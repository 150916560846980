export const isBrowser = () => typeof window !== 'undefined';

export const getLocationPath = () => (isBrowser() ? window.location.pathname : null);

export const getLocationQS = () => (isBrowser() ? window.location.search : null);

export const prepareClassName = (name: string) => String(name).replace(/\s/g, '-').toLowerCase();

export const scrollToSection =
  (id: string, opt: Record<string, string> = { block: 'start', behavior: 'smooth' }) =>
  (e: React.SyntheticEvent<HTMLAnchorElement | HTMLButtonElement>) => {
    e.currentTarget.blur();
    document.getElementById(id)?.scrollIntoView(opt);
  };

export const scrollToClassName = (name: string, opt: Record<string, string> = { block: 'start' }) =>
  document.getElementsByClassName(name)?.[0]?.scrollIntoView(opt);

export const triggerCookiePopupOpen =
  (selector = 'button.ot-floating-button__open') =>
  () => {
    const popupButton = <HTMLButtonElement>document.querySelector(selector);
    popupButton?.click();
    if (isBrowser()) {
      const customWindow = window as Window;
      setTimeout(() => {
        if (customWindow?.OneTrust?.ToggleInfoDisplay) {
          customWindow.OneTrust.ToggleInfoDisplay();
        }
      }, 100);
    }
  };
